<template>
  <div class="text-text1">
    <p class="mb-2">{{title}}</p>
    <div v-if="typeForVi === ''">
      <div class="text-rigth flex gap-2 mb-4">
          <Button :btnSize="'medium'" :textColor="`${ isMultiVisit ? 'text1' : isMultiVisit === null ? 'text1' : 'white'}`" :btnColor="`${ isMultiVisit ? 'gray1' : isMultiVisit === null ? 'gray1' : 'primary'}`" :btnText="'One Visit'" @buttonAction="oneViCall()"/>
          <Button :btnSize="'medium'" :textColor="`${ isMultiVisit ? 'white' : 'text1'}`" :btnColor="`${ isMultiVisit ? 'primary' : 'gray1'}`" :btnText="'Multiple Visits'" @buttonAction="multipleViCall()"/>
          <Button :btnSize="'medium'" :textColor="`${ isUnSchJob ? 'white' : 'text1'}`" :btnColor="`${ isUnSchJob ? 'secondary' : 'gray1'}`" :btnText="'Unschedule Job'" @buttonAction="unScheduleJobSel()"/>
      </div>
      <p v-if="visitErr" class="mb-4 text-error">Please select at list one visit.</p>
    </div>
    <div class="date_picker_box md:grid gap-4 grid-cols-2">
        <div class="flex justify-center">
          <div style="width: 305px" v-if="isMultiVisit === false" class="mb-4">
            <DateTimePickerCustom
                v-model="visitStartDateTime"
                :title="''"
                :showOnlyDate="true"
                :hideGoToday="true"
                :hideBtn="true"
                :roundedCard="true"
                @input="updateDate()"
                >
            </DateTimePickerCustom>
        </div>
        <div style="width: 305px" v-if="isMultiVisit === true" class="mb-4">
            <DateTimePickerMultiple
                v-model="visitStartDateTime"
                :title="''"
                :showOnlyDate="true"
                :hideBtn="true"
                :hideGoToday="true"
                @setMultipleDates="setMultipleDates"
                >
            </DateTimePickerMultiple>
        </div>
        </div>
        <div v-if="isMultiVisit !== null" class="mb-4">
            <div class="w-full mb-4">
              <div v-if="!isAnyTime" class="mb-4">
                <TimePicker :title="'Start Time'" v-model="startTime"/>
              </div>
              <div v-if="!isAnyTime" class="mb-4">
                <TimePicker :title="'End Time'" v-model="endTime"/>
              </div>
              <div v-if="isAnyTime" class="card bg-gray1 items-center rounded-xl p-4  w-full flex gap-2 text-text1  mb-4">
                <div class="flex justify-between gap-2 items-center">
                  <p>Time:</p>
                  <div class="font-semibold heading-4">
                    Anytime
                  </div>
                </div>
              </div>
              <p v-if="timeErr" class="text-error mb-2">End time should be greater than Start time</p>
               <div class="flex justify-end" v-if="title !== 'Shift Time'">
                <p v-if="!isAnyTime" @click="isAnyTime = true" class="text-primary cursor-pointer">Clear Time</p>
                <p v-else @click="isAnyTime = !isAnyTime" class="text-primary cursor-pointer">Set Time</p>
              </div>
            </div>
            <div v-if="selectedVisits.length === 1 && isMultiVisit"  class="mb-4">
              <!-- <div class="mb-4">
                <Dropdown 
              :inputext="selectedRepeat" 
              :inputId="'repeatinputdorpdwn'"
              :lableText="'Repeat'"
              :placholderText="`Repeat`"
              :autoFocus="false"
              :showArrow="true"
              :setReadOnly="true"
              :searchCallApi="false"
              :showPlus="false"
              :keyName="'text'"
              :listId="'repeatlistid'"
              :items="rpeatLIst"
              @selectNewForVal="selectNewRepeat"
              />
              </div> -->

              <div v-if="selectedRepeat !== 'Do not Repeat'" class="mb-4">
                  <div class="">
                    <TextField
                        :inputId="`itmprice`"
                        :inputext="numberOfVisit"
                        :placholderText="``"
                        :lableText="'No of Visit (Max 52 allowed)'"
                        :inputType="'number'"
                        :textRight="false"
                        :maxNumberLimit = "52"
                        :autoFocus="false"
                        :setReadOnly="false"
                        :inputTypeText="'number'"
                        @inputChangeAction="setNomOfVisits" />
                      <!-- <NumberInput
                      :inputId="'itmprice'"
                      :inputext="numberOfVisit"
                      :placholderText="`No of Visit`"
                      :lableText="''"
                      :inputType="'text'"
                      :textRight="false"
                      :autoFocus="false"
                      :setReadOnly="false"
                      @inputChangeAction="data => numberOfVisit = data"/> -->
                  </div>
              </div>
            <div v-if="selectedRepeat !== 'Do not Repeat' && numberOfVisit > 0 " class="flex justify-between">
              <div>
                <p>First Visit</p>
                <p class=" font-semibold">{{firstVisit | MMDDDYYY}}</p>
              </div>
              <div>
                <p>Last Visit</p>
                <p class=" font-semibold">{{lastVisit | MMDDDYYY}}</p>
              </div>
            </div>
          </div>
            
        </div>
    </div>
    <div class="mb-4 sm:w-6/12" v-if="showEmployeeSelection">
      <MultiSelect 
        :inputext="employeeSearchText" 
        :inputId="'empslectinpuid'"
        :lableText="'Assign to'"
        :placholderText="`Select Employee`"
        :autoFocus="false"
        :showArrow="true"
        :setReadOnly="false"
        :searchCallApi="false"
        :showPlus="false"
        :keyName="'fullName'"
        :idKey="'userDetailId'"
        :sectionId="'employeelistsecid'"
        :listId="'employeelistidb'"
        :items="employeeList"
        :selectedValues="selectedEmployee"
        @selectNewForVal="selectEmployee"
      />
      </div>
      <div  v-if="showJobItemSelection && !isItLead">
        <JobItemSelect :unscheduleData="jobDetail"/>
      </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import JobItemSelect from './JobItemSelect.vue'
// import Dropdown from '@/View/components/dropdown.vue'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import DateTimePickerMultiple from '@/View/components/datePickerMultiple.vue'
import TimePicker from './timePicker.vue'
// import TimePicker from '@/View/components/timepicker/timepicker.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
import moment from 'moment'
export default {
  name: 'ScheduleVisit',
  components: {
    JobItemSelect,
    MultiSelect,
    // Dropdown,
    Button,
    TimePicker,
    TextField,
    DateTimePickerCustom,
    DateTimePickerMultiple,
  },
  data () {
    return {
        activeUer: 0,
        isUnSchJob: false,
        isItLead: false,
        typeForVi: '',
        employeeSearchText: '',
        selectedEmployee: [],
        employeeList: [],
        rpeatLIst: [],
        numberOfVisit: 0,
        repeatViType: '',
        selectedRepeat: 'Do not Repeat',
        timeErr: false,
        isAnyTime: false,
        startTime: "11:30",
        endTime: "11:30",
        firstVisit: "",
        lastVisit: "",
        isMultiVisit: null,
        visitStartDateTime: '',
        visitEndDateTime: '',
        selectedVisits: [],
        finalVisitList: [],
        weekDayList: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    }
  },
  props: [ 'isLead', 'visitErr', 'isFromSchedule', 'sTime', 'eTime', 'isSelect', 'type', 'title', 'showEmployeeSelection', 'showJobItemSelection', 'jobDetail', 'isCreateJob', 'isShowJobSection'],
  created() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
  },
  mounted () {
    if (this.jobDetail) {
      if (this.jobDetail.isUnschedule === true) {
        this.getUnscheduleData()
      }
    }
    if (this.isFromSchedule) {
      if (this.isSelect) {
        this.visitStartDateTime = moment(new Date(this.sTime)).format('YYYY-MM-DD')
        this.visitEndDateTime = moment(new Date(this.eTime)).format('YYYY-MM-DD')
        this.setTimes(this.sTime, this.eTime)
        setTimeout(() => {
          this.isMultiVisit = false
          this.isUnSchJob = false
          this.updateDate()
        }, 800);
        this.typeForVi = this.type
      }
    } else if (!this.isCreateJob) {
      this.visitStartDateTime = moment(new Date()).format('YYYY-MM-DD')
      this.visitEndDateTime = moment(new Date()).format('YYYY-MM-DD')
      if (this.type === 'oneVisit') {
        this.typeForVi = this.type
        this.oneViCall()
      }
    }
    this.getEmployeeList()
  },
  beforeDestroy () {
  },
  watch: {
    isShowJobSection: {
      handler () {
        if (this.isShowJobSection && this.jobDetail) {
           if (this.jobDetail.visitList.length > 1) {
              this.isMultiVisit = true
            } else {
              this.isMultiVisit = false
              this.visitStartDateTime = moment(new Date(this.jobDetail.visitList[0].visitStartDateTime)).format('YYYY-MM-DD')
              this.visitEndDateTime = moment(new Date(this.jobDetail.visitList[0].visitEndDateTime)).format('YYYY-MM-DD')
                this.oneViCall()
            }
        }
      }, 
      deep: true
    },
    isLead: {
      handler () {
        this.isItLead = this.isLead
      }, 
      deep: true
    },
    finalVisitList: {
      handler () {
        setTimeout(() => {
         this.$root.$emit('finalvisitlistHandler', this.finalVisitList)
        }, 500);
      }
    },
    numberOfVisit: {
      handler () {
        if (this.numberOfVisit !== null && this.numberOfVisit < 53) {
          this.setVisits(this.numberOfVisit)
        }
      }
    },
    isAnyTime: {
      handler () {
        if (this.isAnyTime) {
          this.finalVisitList.forEach(el => {
            let temp = moment(el.visitStartDateTime).format('YYYY-MM-DD')
             let strDate = temp + ' ' + '12:00'
             let endDate = temp + ' ' + '23:59'
            el.visitStartDateTime = new Date(strDate).toUTCString()
            el.visitEndDateTime = new Date(endDate).toUTCString()
          })
        } else {
          this.oneViCall()
        }
      }
    },
    startTime: {
      handler () {
        this.timeErr = false
           let x = this.startTime.split(':')
            let hour = parseInt(x[0])
            let min = parseInt(x[1])
           let y = this.endTime.split(':')
            let hr2 = parseInt(y[0])
            let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
        this.setVisitTime()
      }
    },
    endTime: {
      handler () {
        this.timeErr = false
        let x = this.startTime.split(':')
            let hour = parseInt(x[0])
            let min = parseInt(x[1])
           let y = this.endTime.split(':')
            let hr2 = parseInt(y[0])
            let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
        this.setVisitTime()
      }
    }
  },
  methods: {
    setNomOfVisits (value) {
      console.log("check vale", parseInt(value));
      if (parseInt(value) > 52) {
        console.log("check in 1");
        setTimeout(() => {
          this.numberOfVisit = 52
        }, 500);
      } else {
        console.log("check in 2");
        this.numberOfVisit = value
      }
    },
    getUnscheduleData () {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.getUnscheduleDetail(
        this.jobDetail.jobId,
          (response) => {
            console.log('response 123 123----------', response.Data)
            this.$root.$emit('getUnscheduleObject', response.Data)
            let temp = response.Data.assignToList
            let array = []
            for (let index = 0; index < temp.length; index++) {
              temp[index].fullName = temp[index].firstName + ' ' + temp[index].lastName
            }
            array = temp
            this.selectEmployee(array)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            document.body.style = 'overflow: visible;'
          },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      );
    },
    setTimes (start, end) {
      let hours = new Date(start).getHours()
        let min = new Date(start).getMinutes() 
        this.startTime = hours + ':' + min

        let hours2 = new Date(end).getHours()
        let min2 = new Date(end).getMinutes()
        this.endTime = hours2 + ':' + min2
    },
    setVisitTime () {
      let templist = []
      this.finalVisitList.forEach(el => {
          let temp = moment(el.visitStartDateTime).format('YYYY-MM-DD')
            let strDate = temp + ' ' + this.startTime
            let endDate = temp + ' ' + this.endTime
            let obj = {
              visitStartDateTime: new Date(strDate).toUTCString(),
              visitEndDateTime: new Date(endDate).toUTCString(),
              Error: this.timeErr,
              isUnschedule: false
            }
          templist.push(obj)
        })
        setTimeout(() => {
          this.finalVisitList = templist
        }, 200);
    },
    selectEmployee (emplist) {
      this.selectedEmployee = []
      let tempEmpList = []
      emplist.map((data) => {
        let obj = {
          userDetailId: data.userDetailId,
          fullName: data.fullName
        }
        tempEmpList.push({userDetailId:data.userDetailId})
        this.selectedEmployee.push(obj)
      })
      setTimeout(() => {
         this.$root.$emit('setSelectedEmpHandler', tempEmpList)
      }, 200);
    },
    setVisits (numOfvi) {
      let arr = []
      if ( parseInt(numOfvi) > 0) {
        let days = 0
        if (this.repeatViType === 'week') {
          days = 7
        } else if (this.repeatViType === 'everyday') {
          days = 1
        } else if (this.repeatViType === '2week') {
          days = 14
        } else if (this.repeatViType === 'month') {
          days = new Date(this.visitStartDateTime)
        }
        if (this.repeatViType === 'week' || this.repeatViType === '2week') {
          let myDate = new Date(this.visitStartDateTime)
          arr.push(myDate)
          for (let i = 1; i < parseInt(numOfvi) + 1; i++) {
            if (i > 1) {
              let val = this.addDays(myDate, days)
              myDate = val
              arr.push(val)
            }
          }
         } else if (this.repeatViType === 'everyday') {
          let myDate = new Date(this.visitStartDateTime)
          arr.push(myDate)
          for (let i = 1; i < parseInt(numOfvi) + 1; i++) {
            if (i > 1) {
              let val = this.addDays(myDate, days)
              myDate = val
              arr.push(val)
            }
          }
        } else if (this.repeatViType === 'month') {
          let myDate = new Date(this.visitStartDateTime)
          arr.push(myDate)
          for (let i = 1; i < parseInt(numOfvi) + 1; i++) {
            if (i > 1) {
              let month = new Date(myDate).getMonth()
              let val = new Date (new Date(myDate).setMonth(month + 1));
              myDate = val
              arr.push(new Date(val))
            }
          }
        }
        this.lastVisit = arr[arr.length - 1]
         if (arr.length > 0) {
          this.finalVisitList = []
          arr.map(visit => {
             let strDate = moment(new Date(visit)).format('YYYY-MM-DD') + ' ' + this.startTime
             let endDate = moment(new Date(visit)).format('YYYY-MM-DD') + ' ' + this.endTime
            let obj = {
              visitStartDateTime: new Date(strDate).toUTCString(),
              visitEndDateTime: new Date(endDate).toUTCString(),
              isUnschedule: false
            }
          this.finalVisitList.push(obj)
          })
        }
      }
    },
    addDays(theDate, days) {
        return new Date(theDate.getTime() + days*24*60*60*1000);
    },
    selectNewRepeat (data) {
        this.selectedRepeat = data !== null ? data.text : 'Do not Repeat'
        this.repeatViType = data !== null ? data.type : ''
        this.setVisits(this.numberOfVisit)
        this.$root.$emit('setSheduleDataEmpHandler',{repeatedType: this.repeatViType, repeatedDayOrDate: data.val})
        
    },
    setMultipleDates (list) {
      this.selectedVisits= []
        if (list.length > 0) {
          this.selectedVisits = list
          this.finalVisitList = []
          if (this.isAnyTime) {
             list.map(visit => {
                let strDate = moment(new Date(visit)).format('YYYY-MM-DD') + ' ' + '12:00'
                let endDate = moment(new Date(visit)).format('YYYY-MM-DD') + ' ' + '23:59'
                let obj = {
                  visitStartDateTime: new Date(strDate).toUTCString(),
                  visitEndDateTime: new Date(endDate).toUTCString(),
                  isUnschedule: false
                }
              this.finalVisitList.push(obj)
              })
          } else {
            list.map(visit => {
              let strDate = moment(new Date(visit)).format('YYYY-MM-DD') + ' ' + this.startTime
              let endDate = moment(new Date(visit)).format('YYYY-MM-DD') + ' ' + this.endTime
              let obj = {
                visitStartDateTime: new Date(strDate).toUTCString(),
                visitEndDateTime: new Date(endDate).toUTCString(),
                isUnschedule: false
              }
            this.finalVisitList.push(obj)
            })
          }
        }
        if (this.isMultiVisit && list.length === 1) {
         this.numberOfVisit = 0
          this.selectedRepeat = 'Do not Repeat'
          this.visitStartDateTime = list[0]
          this.rpeatLIst = []
          let repArr = [{text: 'Do not Repeat', value: -1, type: ''}]
          let ew = {
              text: `EveryDay`,
              val: new Date(this.visitStartDateTime).getDay(),
              type: 'everyday',
            };
          repArr.push(ew);
          ew = {
              text: `Every week on ${this.weekDayList[new Date(this.visitStartDateTime).getDay()]}`,
              val: new Date(this.visitStartDateTime).getDay(),
              type: 'week',
            };
          repArr.push(ew);
          ew = {
            text: `Every 2 weeks on ${this.weekDayList[new Date(this.visitStartDateTime).getDay()]}`,
            val: new Date(this.visitStartDateTime).getDay(),
            type: '2week',
          };
          repArr.push(ew);
          ew = {
            text: `Every month on ${new Date(this.visitStartDateTime).getDate()}`,
            val: new Date(this.visitStartDateTime).getDate(),
            type: 'month',
          };
          repArr.push(ew);
          this.rpeatLIst = repArr
          this.firstVisit = new Date(this.visitStartDateTime)
          this.lastVisit = new Date(this.visitStartDateTime)
        }
    },
    updateDate () {
      let list = []
      list.push(this.visitStartDateTime)
      this.setMultipleDates(list)
    },
    oneViCall () {
        this.isMultiVisit = false
        this.isUnSchJob = false
        this.setHourTime()
    },
    unScheduleJobSel () {
      this.isMultiVisit = null
      this.isUnSchJob = true
      this.finalVisitList = []
        let obj = {
          visitStartDateTime: '',
          visitEndDateTime: '',
          Error: this.timeErr,
          isUnschedule: true
        }
      this.finalVisitList.push(obj)
    },
    multipleViCall () {
        this.isMultiVisit = true
        this.isUnSchJob = false
        this.setHourTime()
    },
    setHourTime () {
      let hours = new Date().getHours()
        let min = new Date().getMinutes()
        hours = min > 45 &&  min < 60 ? hours + 1 : hours
        let minutes = min <= 15 ? 15 : min <= 30 ? 30 : min <= 45 ? 45 : min <= 60 ? 0 : 0
        
        this.startTime = hours + ':' + minutes
        this.endTime = hours + 1 + ':' + minutes
        this.updateDate()
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let tempList = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map(el => {
              tempList.push({...el, fullName: el.firstName + ' ' + el.lastName})
              if (el.userDetailId === this.activeUer && this.isCreateJob) {
                this.selectedEmployee.push({
                  userDetailId: el.userDetailId,
                  fullName: el.firstName + ' ' + el.lastName
                })
              }
            })
          }
          this.employeeList = tempList
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
}
</script>
