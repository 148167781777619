<template>
  <div class="mt-4">
    <div
      class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl w-full"
    >
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Create Job</h1>
        <div class="flex items-center gap-3"></div>
      </div>
    </div>
    <div class="flex justify-center">
      <div
        class="card rounded-xl bg-white p-4 my-4 sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12"
      >
        <Tabs :tabsList="tabTitle" />
        <div class="p-4 pb-0">
          <div v-show="tabTitle[0].active" class="grid grid-cols-2 gap-4">
            <div class="mb-4 sm:mb-0">
              <Dropdown
                :disabled="isDisable"
                :inputext="searchForCustomer"
                :inputId="'searchwp-Customer'"
                :lableText="'Customer'"
                :placholderText="`Customer`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="true"
                :fieldError="errObj.custErr !== ''"
                :showPlus="true"
                :keyName="'companyName'"
                :listId="'customerlistid'"
                :items="customersList"
                @enterkeyPressAction="errObj.custErr = ''"
                @inputChangeAction="callsearchCust"
                @addNew="addNewCustomer"
                @selectNewForVal="selectNewCustomer"
              />
              <p v-if="errObj.custErr !== ''" class="text-error heading-7">
                {{ errObj.custErr }}
              </p>
            </div>
            <div
              v-if="custDetail !== null"
              class="mb-4 sm:mb-0 flex items-center"
            >
              <!-- <StarRating :star-size="40" active-color="#107591" inactive-color="#1295ba0f" v-model="custDetail.rating" /> -->
              <!-- <StarRating
                :show-rating="false"
                :star-size="30"
                active-color="#107591"
                inactive-color="#1295ba0f"
                :active-on-click="true"
                :animate="false"
                :read-only="true"
                v-model="custDetail.rating"
              /> -->
              <div
                v-if="custDetail.hotNotes !== ''"
                class="flex items-center ml-4  pt-2"
              >
                <span
                  @click="showAddNote = true"
                  class="text-primary cursor-pointer"
                  ><i class="fa-solid fa-pen-to-square h-5 w-5"></i
                ></span>
              </div>
              <div
                v-if="!showHotNote && custDetail.hotNotes !== ''"
                class="flex items-center px-4 pt-2"
              >
                <span
                  @click="showHotNote = true"
                  class="text-primary cursor-pointer"
                  >Show Hot Notes</span
                >
              </div>
              <div v-else-if="showHotNote" class="flex items-center px-4 pt-2">
                <span
                  @click="showHotNote = false"
                  class="text-primary cursor-pointer"
                  >Hide Hot Notes</span
                >
              </div>
              <div
                v-else-if="custDetail.hotNotes === ''"
                class="flex items-center px-4 pt-2"
              >
                <span
                  @click="showAddNote = true"
                  class="text-primary cursor-pointer"
                  >Enter Hot Notes</span
                >
              </div>
            </div>
            <div
              v-if="custDetail !== null && showHotNote"
              class="mb-4 sm:mb-0 flex items-center col-span-2"
            >
              <div>
                <p class="text-error">{{ custDetail.hotNotes }}</p>
              </div>
            </div>
            <div class="mb-4 sm:mb-0 col-span-2">
              <TextField
                :inputId="'jobtitlename'"
                :textMaxlength="100"
                :showcharLimit="true"
                :inputext="addJobObj.jobTitle"
                :fieldError="errObj.titleErr !== ''"
                :placholderText="`Job Title`"
                :lableText="'Job Title'"
                :autoFocus="false"
                @keyPressAction="errObj.titleErr = ''"
                @inputChangeAction="(data) => (addJobObj.jobTitle = data)"
              />
              <p class="text-error heading-7">{{ errObj.titleErr }}</p>
            </div>
            <div class="mb-4 sm:mb-0 col-span-2">
            <div
              v-if="showAddSelectAddr"
              class=""
            >
              <!-- <Dropdown
                :inputext="jobDisplayAdd"
                :inputId="'jobloactioninput'"
                :lableText="'Select job location'"
                :placholderText="`Select job location`"
                :autoFocus="false"
                :showPlus="true"
                :showArrow="true"
                :setReadOnly="true"
                :searchCallApi="false"
                :keyName="'addressText'"
                :fieldError="errObj.addressErr !== ''"
                :listId="'addresslistforjob'"
                :items="addressList"
                @onClickInputAction="errObj.addressErr = ''"
                @addNew="addNewAddress"
                @selectNewForVal="selectJobAddress"
              /> -->
              <div>
                <div v-for="(add,ind) in addressList" :key="ind">
                  <div @click="selectJobAddress(add, ind)" class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1">
                    <div class="flex items-center justify-between">
                      <div class="flex gap-1">
                        <p v-if="!add.selected" class="text-gray3">
                        <i class="fa-regular fa-circle h-5 w-5"></i>
                      </p>
                      <span v-else class="text-primary">
                        <i class="fa-solid fa-circle-check h-5 w-5"></i>
                      </span>
                      <span class=" font-semibold">
                        {{add.addressTitle === '' ? 'Other' : add.addressTitle}}
                      </span>
                      </div>
                      <p>{{add.displayDistance}}</p>
                    </div>
                    <div>
                      <p>{{add.fullAddress}}</p>
                    </div>
                  </div>
                </div>  
              </div>
              
              <p v-if="errObj.addressErr !== ''" class="text-error heading-7">
                {{ errObj.addressErr }}
              </p>

              
            </div>
            <div class=" col-span-2">
              <div @click="checkboxCheck()" class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1">
                    <div class="flex items-center">
                      <div class="flex gap-1">
                        <p v-if="!isOfficeAddressSel" class="text-gray3">
                          <i class="fa-regular fa-circle h-5 w-5"></i>
                        </p>
                        <span v-else class="text-primary">
                          <i class="fa-solid fa-circle-check h-5 w-5"></i>
                        </span>
                      <span class=" font-semibold">Office Location
                      </span>
                      </div>
                    </div>
                  </div>
            </div>
            <div class=" col-span-2">
              <div @click="addNewAddress()" class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1">
                    <div class="flex items-center justify-between">
                      <div class="flex gap-1">
                        <p v-if="!isAddedNewAddress" class="text-gray3 mb-0">
                          <i class="fa-regular fa-circle h-5 w-5"></i>
                        </p>
                        <span v-else class="text-primary">
                          <i class="fa-solid fa-circle-check h-5 w-5"></i>
                        </span>
                      <span class=" font-semibold">Other Location
                      </span>
                      </div>
                      <div>
                          <p v-if="isAddedNewAddress">{{otherLocationDistance}}</p>
                      </div>
                    </div>
                    <div v-if="isAddedNewAddress" class="">
                      {{addJobObj.fullAddress}}
                    <div v-if="isAddedNewAddress" class="flex items-center">
                      <CheckBoxInput :checkValue="addJobObj.isAddressAddToCustomer" :label="``" :size="'small'" :disabled="false" @setIsActive="addJobObj.isAddressAddToCustomer = !addJobObj.isAddressAddToCustomer"/>
                      <span
                        class="pl-0 text-text2 cursor-pointer"
                        @click.stop.prevent="addJobObj.isAddressAddToCustomer =  !addJobObj.isAddressAddToCustomer"
                        >Add address in customer file</span
                      >
                    </div>
                    </div>
                  </div>
            </div>
            </div>
            

            <div class="flex justify-end col-span-2">
                <div class="text-rigth flex gap-2">
                  <Button
                    :btnSize="'medium'"
                    :textColor="'text1'"
                    :btnColor="'gray1'"
                    :btnText="'Cancel'"
                    @buttonAction="$router.go(-1)"
                  />
                  <Button
                    :btnSize="'medium'"
                    :textColor="'white'"
                    :btnColor="'success'"
                    :btnText="'Next'"
                    @buttonAction="crateJob()"
                  />
                </div>
              </div>
          </div>
          <div v-show="tabTitle[1].active" class="sm:grid grid-cols-2 gap-4">
            <div class="col-span-2 mb-4 sm:mb-0">
              <TextAreaInput
                :inputId="'discriptioncon'"
                :inputext="stepTwoObj.jobDescription"
                :placholderText="`Write description here...`"
                :lableText="'Job Description'"
                :inputType="'text'"
                :autoFocus="false"
                :textMaxlength="1000"
                :showcharLimit="true"
                :cols="50"
                :rows="3"
                :setReadOnly="false"
                @inputChangeAction="
                  (data) => (stepTwoObj.jobDescription = data)
                "
              />
            </div>
            <div class="col-span-2 mb-4 sm:mb-0">
              <div class="flex items-center py-1">
                 <CheckBoxInput :checkValue="stepTwoObj.isVipJob" :label="`VIP Job`" :size="'small'" :disabled="false" @setIsActive="stepTwoObj.isVipJob = !stepTwoObj.isVipJob"/>
                  </div>
            </div>
            <div class="col-span-2 mb-4 sm:mb-0">
               <p class="label_css"  >Select Job Type</p>
               <div class="flex flex-wrap">
                 <span class="" v-for="(type, index) in jobTypeList" :key="index" >
                  <span @click="selectJobType(type)" :class="`mb-1 cursor-pointer flex items-center justify-center w-fit heading-6 whitespace-nowrap border rounded-2xl  text-${stepTwoObj.jobTypeId === type.jobTypeId ? 'primary' : ''} border-${stepTwoObj.jobTypeId === type.jobTypeId ? 'primary' : 'gray2'} px-2 py-1  mr-1`">
                    {{type.jobTypeName}}</span>
                </span> 
               </div>
            </div>
            <div class="col-span-2 mb-4 sm:mb-0">
               <p class="label_css"  >Job Estimation</p>
               <div class="flex flex-wrap sm:gap-4">
                <div class="text-left gap-2 pt-1 pb-1 pl-2 flex items-center">
                    <div class=""><span>Hours:</span></div>
                    <div class="">
                        <button  class="timePick_btn"
                        @click="decreaseHours()"  small>
                        <span>-</span>
                        </button>
                    </div>
                    <input  @focus="$event.target.select()" class="minuteBox cust_text_field pl-3 w-12 border focus:border-primary rounded flext items-center justify-center px-1 py-1 " v-model="hourInputValue"  v-mask="'##'">
                    <div class="ml-1">
                      <button  class="timePick_btn"
                        @click="increaseHours()"  small>+
                    </button>
                    </div>
                </div>
                <div class="text-left gap-2 pt-1 pb-1 pl-2 flex items-center">
                    <div class=""><span>Minutes:</span></div>
                    <div class="">
                        <button  class="timePick_btn"
                        @click="decreaseMin()"  small>
                        <span>-</span>
                        </button>
                    </div>
                    <input readonly  class="minuteBox cust_text_field pl-3 w-12 border rounded flext items-center justify-center px-1 py-1 " v-model="minInputValue"  v-mask="'##'">
                    <div class="ml-1">
                      <button  class="timePick_btn"
                        @click="increaseMin()"  small>+
                    </button>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-span-2 mb-4 sm:mb-0">
              <div class="flex items-center py-1">
                 <CheckBoxInput :checkValue="stepTwoObj.isBillable" :label="`Billable Job`" :size="'small'" :disabled="false" @setIsActive="stepTwoObj.isBillable = !stepTwoObj.isBillable"/>
                  </div>
            </div>
            <div class="mb-4 sm:mb-0">
              <p class="label_css"  >Job  Checklist</p>
              <div >
              <div v-for="(item, ch) in jobCheckList" :key="ch" class="mb-2 flex items-center">
                <span
                  @click="deleteCheckItem(ch)"
                  class="text-error cursor-pointer heading-3 pr-4" >
                  <i class="fa-solid fa-trash-can"></i>
                  </span>
                <TextField class="w-full"
                :inputId="`ChekclistTitle ${ch}`"
                :textMaxlength="100"
                :inputext="item.checkLiTitle"
                :placholderText="`Chekclist Title`"
                :lableText="''"
                :autoFocus="false"
                @inputChangeAction="(data) => (item.checkListTitle = data)"
              />
              <span v-if="jobCheckList.length - 1 === ch"
                  @click="addcheckLiItem"
                  class="text-primary cursor-pointer heading-3 pl-4" >
                  <i class="fa-solid fa-circle-plus"></i>
                  </span>
              </div>
              </div>
            </div>
            <div class="flex justify-end col-span-2">
                <div class="text-rigth flex gap-2">
                  <Button
                    :btnSize="'medium'"
                    :textColor="'text1'"
                    :btnColor="'gray1'"
                    :btnText="'Previous'"
                    @buttonAction="goStepOne"
                  />
                  <Button
                    :btnSize="'medium'"
                    :textColor="'white'"
                    :btnColor="'success'"
                    :btnText="'Next'"
                    @buttonAction="gostepThree"
                  />
                </div>
              </div>
          </div>
          <div v-show="tabTitle[2].active" class="grid grid-cols-2 gap-4">
            <div v-if="jobDetailObj" class=" col-span-2">
              <span v-if="!stepTwoObj.isBillable">Non Billable</span>
              <span v-else>Billable</span> // <span>Est: {{displayEstimation}}</span> // {{ jobDetailObj.JobTypeName}} <br>
              <span>{{displayDistance}}</span>
            </div>
            <div class=" col-span-2">
              <div>
                <SheduleVisit :isFromSchedule="false" :title="'Schedule'" :showEmployeeSelection="true" :showJobItemSelection="true" :visitErr="visitErr" :jobDetail="jobDetailObj" :isCreateJob="true" :isShowJobSection="showStepThree" />
              </div>
            </div>
            <div class=" col-span-2">
              <div>
                <p v-if="stepThreeObj.toolList.length > 1" class=" text-gray4 heading-4 font-bold mb-2">Tool list:</p>
                <div v-for="(tool, ind) in stepThreeObj.toolList" :key="ind" class="p-2 mb-2 cust_card">
                  <p class="text-text1">{{tool.toolName}}</p>
                  <p class="heading-7 text-text2">{{tool.toolDescription}}</p>
                </div>
              </div>
              <div>
                <span @click="isSelectTool = true" class="text-primary font-bold cursor-pointer">+ Add {{stepThreeObj.toolList.length > 0 ? 'More': ''}} Tools</span>
              </div>
            </div>
            <div class="flex justify-end col-span-2">
                <div class="text-rigth flex gap-2">
                  <Button
                    :btnSize="'medium'"
                    :textColor="'text1'"
                    :btnColor="'gray1'"
                    :btnText="'Previous'"
                    @buttonAction="goStepTwo"
                  />
                  <Button
                    :btnSize="'medium'"
                    :textColor="'white'"
                    :btnColor="'success'"
                    :btnText="'Save'"
                    @buttonAction="saveStepThree"
                  />
                </div>
              </div>
          </div>
        </div>
        
      </div>
    </div>
    <div></div>
    <AddCustomer v-if="addCustomer" />
    <UpdateCompanyInfo
      v-if="showAddNote"
      :dialogTtile="'Update Hot Notes'"
      :updateCustData="custDetail"
    />
    <AddressAdd :showAdditional="true" v-show="addNewjobAdd" :addedAddress="addedAddress" />
    <!-- <EditAddress :jobDetail="addJobObj" v-if="EditAddress" :showAdditional="true" /> -->
    <SelectTools @uppdateToollist="uppdateToollist" :toolList="toolMainList" @selectToolHandler="selectToolHandler" v-if="isSelectTool" />
    <!--     <AddEditItems v-if="addItems" :itemsSelected="addJobObj.checkList"></AddEditItems> -->
  </div>
</template>
<script>
// import AddEditItems from '@/View/job/visit/components/AddEditItems.vue'
import MyJobApp from "@/api/MyJobApp.js";
import Button from "@/View/components/globalButton.vue";
import TextAreaInput from "@/View/components/textAreaInput.vue";
import SheduleVisit from './components/SheduleVisit.vue'
import timeZoneWithCountryCode from '@/assets/timeZoneWithCountryCode.json'
import milesCountry from '@/assets/milesCountry.json'
// import StarRating from "vue-star-rating";
import Dropdown from "@/View/components/dropdown.vue";
import TextField from "@/View/components/textfield.vue";
import deboucneMixin from "@/mixins/debounce.js";
import Tabs from "../components/tabs.vue";
import AddCustomer from "../customer/components/AddCustomer.vue";
import UpdateCompanyInfo from "../customer/components/UpdateCompanyInfo.vue";
import AddressAdd from '../components/AddressAdd.vue';
import CheckBoxInput from '../components/checkBoxInput.vue';
import SelectTools from './components/selectTools.vue';
// import EditAddress from '../components/EditAddress.vue';
export default {
  name: "createJob",
  components: {
    Tabs,
    // StarRating,
    Button,
    TextAreaInput,
    // AddCustomer,
    AddressAdd,
    TextField,
    SheduleVisit,
    Dropdown,
    AddCustomer,
    UpdateCompanyInfo,
    CheckBoxInput,
    SelectTools,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      tabTitle: [
        {
          name: "Customer & Location",
          complete: false,
          active: true,
          disabled: false,
          canViewPermission: true,
        },
        {
          name: "Description & Job Type",
          complete: false,
          active: false,
          disabled: true,
          canViewPermission: true,
        },
        {
          name: "Schedule & Assignee",
          complete: false,
          active: false,
          disabled: true,
          canViewPermission: true,
        },
      ],
      errObj: {
        custErr: "",
        titleErr: "",
        addressErr: "",
      },
      addJobObj: {
        jobId: 0,
        customerId: 0,
        jobNumber: "",
        jobTitle: "",
        jobStartTime: "",
        jobEndTime: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        latitude: 0,
        longitude: 0,
        geofenceRangeDiameter: 0,
        fullAddress: "",
        houseNo: "",
        floorNumber: "",
        buildingName: "",
        directionNote: "",
        contactNumber: "",
        contactName: "",
        isAddressAddToCustomer: false,
        requestType: "",
        detailId: 0,
        addressTitle: '',
      },
      stepTwoObj: {
        jobId: 0,
        jobDescription: "",
        isVipJob: false,
        isBillable: false,
        jobTypeId: 0,
        jobEstimation: 0,
        visitCheckList: "",
      },
      stepThreeObj: {
        jobId: 0,
        visitList: [],
        isUnschedule: false,
        assignToList: [],
        itemList: [],
        toolList: [],
      },
      addedAddress: null,
      showStepThree: false,
      visitErr: false,
      isSelectTool: false,
      isAddedNewAddress: false,
      addNewjobAdd: false,
      isOfficeAddressSel: false,
      showAddNote: false,
      jobDisplayAdd: "",
      showAddSelectAddr: false,
      showHotNote: false,
      addCustomer: false,
      custDetail: null,
      isDisable: false,
      resetPaginationValues: false,
      searchForCustomer: "",
      searchApitext: "",
      customersList: [],
      addressList: [],
      jobTypeList: [],
      hourInputValue: 0,
      minInputValue: 0,
      jobDetailObj: null,
      jobCheckList: [],
       isTimerRunningStepTwo: false,
      intervalIdStepTwo: null,
       isTimerRunningStepThree: false,
      intervalIdStepThree: null,
      displayEstimation: '',
      LoginUserlatLong: null,
      OfficelatLong: null,
      displayDistance: '',
      toolMainList: [],
      timeCounter: 0,
      displayJobTypeName: '',
      otherLocationDistance: '',
    };
  },
  created() {
    if (this.$route.query.jobId > 0) {
    // this.$router.push({name: 'jobDetail', params: {jobId: parseInt(this.$route.query.jobId)}})
    }
    if (this.$route.query.requestType) {
      this.addJobObj.requestType = this.$route.query.requestType === 'request' ? this.$route.query.requestType : ''
      this.addJobObj.detailId =this.$route.query.detailId ? Number(this.$route.query.detailId) : 0
    }
    
    if (this.$route.query.startTime && this.$route.query.endTime) {
      let sTime = JSON.parse(this.$route.query.startTime)
      let eTime = JSON.parse(this.$route.query.endTime)
      this.addJobObj.jobStartTime = new Date(sTime).toUTCString();
      this.addJobObj.jobEndTime = new Date(eTime).toUTCString();
    }
  },
  computed: {},
  watch: {
    tabTitle: {
      handler() {
        if (this.tabTitle[1].active) {
          clearInterval(this.intervalIdStepTwo);
          this.isTimerRunningStepTwo = true
          this.StartTimerSt1()
        }
        if (this.tabTitle[2].active) {
          clearInterval(this.intervalIdStepThree);
          this.isTimerRunningStepThree = true
          this.showStepThree = true
          this.StartTimerSt3()
        }
        if (this.tabTitle[0].active) {
          // clearInterval(this.intervalIdStepTwo);
          // clearInterval(this.intervalIdStepThree);
          // this.isTimerRunningStepThree = false
          // this.isTimerRunningStepTwo = false
        }
      }, deep: true
    },
    timeCounter: {
      handler() {
      }, deep: true
    }
  },
  mounted() {
    if (this.$route.query.jobId > 0) {
      this.getsJobDetail(true);
    }
    this.LoginUserlatLong = JSON.parse(localStorage.getItem('LoginUserlatLong'))
    this.OfficelatLong = JSON.parse(localStorage.getItem('orgInfo'))
    if (this.$route.query.customerId > 0) {
      this.isDisable = true;
      this.getCustLeadInfo(parseInt(this.$route.query.customerId));
    }
    this.$root.$on("addAddressHandler", (response, data) => {
      if (response && data !== null && this.addNewjobAdd) {
        this.addedAddress = data
        this.setAddress(data);
      }
      this.addNewjobAdd = false;
    });
    this.getCustomerList();
    this.getToolList();
    this.jobTypelist();
    this.$root.$on("updateCustHandler", (response) => {
      this.showAddNote = false;
      if (response) {
        this.getCustDetails(this.custDetail.customerId);
      }
    });
    this.$root.$on("addCustomerHandler", (response, data) => {
      if (response && this.addCustomer) {
        this.selectNewCustomer(data);
      }
      this.addCustomer = false;
    });
    this.$root.$on("setNewTabActive", (index) => {
      this.tabsSet(index);
    });
    this.getCustList = this.debounce(function () {
      this.getCustomerList();
    }, 500);
    this.$root.$on('finalvisitlistHandler', (response) => {
      if (response[0].isUnschedule) {
        this.stepThreeObj.visitList = []
        this.stepThreeObj.isUnschedule = true
      } else {
        this.stepThreeObj.visitList = response
        this.stepThreeObj.isUnschedule = false
      }
      this.visitErr = false
    })
    this.$root.$on('setSelectedEmpHandler', (response) => {
      this.stepThreeObj.assignToList = response
    })
    this.$root.$on('setItemSelectedHandler', (response) => {
      this.stepThreeObj.itemList = response
    })
  },
  beforeDestroy() {
    this.isTimerRunningStepTwo = false;
    this.StopApiSteptwo()
    this.isTimerRunningStepThree = false;
    this.StopApiStepthree()
    this.$root.$off("setNewTabActive");
    this.$root.$off("updateCustHandler");
    // this.$root.$off("addEditCheckList");
    this.$root.$off("addCustomerHandler");
    this.$root.$off("setItemSelectedHandler");
    this.$root.$off("addAddressHandler");
    this.$root.$off("finalvisitlistHandler");
    this.$root.$off("setSelectedEmpHandler");
    // this.$root.$off("setSheduleDataEmpHandler");
  },
  methods: {
    goStepTwo () {
      this.tabTitle[1].active = true;
      this.tabTitle[0].active = false;
      this.tabTitle[2].active = false;
    },
    saveStepThree () {
      this.callApiStepThree(true)
      this.StopApiStepthree()
    },
    uppdateToollist (data) {
      this.toolMainList.push(data)
    },
    selectToolHandler (data) {
      if (data !== null) {
        this.stepThreeObj.toolList = data
      }
      this.isSelectTool = false
    },
    StartTimerSt1() {
      if (this.isTimerRunningStepTwo) {
        this.intervalIdStepTwo = setInterval(this.callApiSteptwo, 10000);
      } else {
        clearInterval(this.intervalIdStepTwo);
      }
    },
    callApiSteptwo() {
      let list = []
      this.jobCheckList.forEach(element => {
        if (element.checkLiTitle.trim() !== '') {
          list.push(element)
        }
      });
      this.stepTwoObj.visitCheckList = JSON.stringify(list)
      this.stepTwoObj.jobEstimation = (Number(this.hourInputValue) * 60) + Number(this.minInputValue)
        MyJobApp.updateJobStep2(
          this.stepTwoObj,
          () => {
            this.tabTitle[1].complete = true;
            if (!this.tabTitle[1].active) {
              this.isTimerRunningStepTwo = false;
              this.StopApiSteptwo()
            }
          },
          (error) => {
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: error.message,
              color: "error",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );

    },
    StopApiSteptwo() {
      clearInterval(this.intervalIdStepTwo);
      this.isTimerRunningStepTwo = false;
    },
    StartTimerSt3() {
      if (this.isTimerRunningStepThree) {
        this.intervalIdStepThree = setInterval(this.callApiStepThree, 10000);
      } else {
        clearInterval(this.intervalIdStepThree);
      }
    },
    callApiStepThree(goOnDetail) {
      if (goOnDetail) {
        this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      }
        MyJobApp.updateJobStep3(
          this.stepThreeObj,
          () => {
            if (goOnDetail) {
              setTimeout(() => {
                this.$store.dispatch("SetLoader", { status: false, message: "" });
                this.$router.push({name: 'jobDetail', params: {jobId: this.jobDetailObj.jobId}})
              }, 200);
            }
            this.tabTitle[2].complete = true;
            if (!this.tabTitle[2].active) {
              this.isTimerRunningStepThree = false;
              this.StopApiStepthree()
            }
          },
          (error) => {
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: error.message,
              color: "error",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );

    },
    StopApiStepthree() {
      clearInterval(this.intervalIdStepThree);
      this.isTimerRunningStepThree = false;
    },
    
    goStepOne () {
      this.tabTitle[0].active = true;
      this.tabTitle[1].active = false;
      this.tabTitle[2].active = false;
    },
    gostepThree () {
      this.StopApiSteptwo()
      this.callApiSteptwo()
      this.tabTitle[0].active = false;
      this.tabTitle[1].active = false;
      this.tabTitle[1].complete = true;
      this.tabTitle[2].active = true;
    },
    deleteCheckItem (index) {
      this.jobCheckList.splice(index,1)
    },
    addcheckLiItem () {
      this.jobCheckList.push({
        checkLiTitle: ''
      })
    },
    decreaseHours() {
      if (this.hourInputValue > 0) {
        this.hourInputValue = Number(this.hourInputValue) - 1
      }
      this.setDisplayEstimation()
    },
    increaseHours() {
      if (this.hourInputValue < 99) {
        this.hourInputValue = Number(this.hourInputValue) + 1
      }
      this.setDisplayEstimation()
    },
    decreaseMin() {
      if (this.minInputValue === 15) {
        this.minInputValue = 0
      } else if (this.minInputValue > 45) {
        this.minInputValue = 45
      } else if (this.minInputValue > 30) {
        this.minInputValue = 30
      } else if (this.minInputValue > 15) {
        this.minInputValue = 15
      }
      this.setDisplayEstimation()
    },
    increaseMin() {
      if (this.minInputValue < 15) {
        this.minInputValue = 15
      } else if (this.minInputValue < 30) {
        this.minInputValue = 30
      } else if (this.minInputValue < 45) {
        this.minInputValue = 45
      }
      this.setDisplayEstimation()
    },
    setDisplayEstimation() {
      let estimation = (Number(this.hourInputValue) * 60) + Number(this.minInputValue);

      // Calculate hours and minutes
      let hours = Math.floor(estimation / 60); // Get the whole number of hours
      let min = estimation % 60; // Get the remaining minutes
      let minutes = Math.round(min); // Get the remaining minutes

      // Format the output
      let formattedTime = `${ hours > 0 ? hours.toString().padStart(2, '0') + ' hr' : ''} ${hours > 0 && minutes > 0 ? "," : ''} ` + ` ${minutes > 0 ? ` ${minutes.toString().padStart(2, '0')} mins` : ''}`;

      this.displayEstimation = formattedTime
    },
    selectJobType(data) {
      console.log('dattttttttttt', data);
      this.jobCheckList = JSON.parse(data.jobCheckList)
      this.hourInputValue = Math.floor(data.typeEstimationMinutes / 60)
      this.minInputValue = data.typeEstimationMinutes % 60
      this.stepTwoObj.jobTypeId = data.jobTypeId
      this.displayJobTypeName = data.jobTypeName
      this.setDisplayEstimation()
    },
    addNewAddress() {
      this.addNewjobAdd = true;
    },
    tabsSet(index) {
      this.tabTitle.forEach((element, i) => {
        if (index === i) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    },
    callsearchCust(text) {
      this.searchApitext = text;
      this.getCustList();
    },
    getCustomerList() {
      this.customersList = [];
      MyJobApp.getGlobalCustLeadList(
        0,
        500,
        this.searchApitext,
        "",
        (response) => {
          let tempList =
            response.Data.tableRow !== null ? response.Data.tableRow : [];
          tempList.forEach((e) => {
            let lead = e.isLead ? "(lead)" : "(customer)";
            e.companyName = e.companyName + " " + lead;
            this.customersList.push(e);
          });
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.resetPaginationValues = false;
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    addNewCustomer() {
      this.addCustomer = true;
    },
    selectNewCustomer(data) {
      this.errObj.addressErr = "";
      if (data !== null) {
        this.islead = data.isLead;
        this.jobDisplayAdd = "";
        this.addJobObj.addressLine1 = "";
        this.addJobObj.addressLine2 = "";
        this.addJobObj.city = "";
        this.addJobObj.state = "";
        this.addJobObj.zip = "";
        this.addJobObj.latitude = 0;
        this.addJobObj.longitude = 0;
        this.addJobObj.country = "";
        this.addJobObj.directionNote = "";
        this.addJobObj.houseNo = "";
        this.addJobObj.floorNumber = "";
        this.addJobObj.buildingName = "";
        this.errObj.custErr = "";
        this.searchForCustomer = data.companyName;
        this.addJobObj.customerId = data.customerId;
        this.showAddSelectAddr = true;
        this.getCustDetails(data.customerId);
      } else {
        this.searchForCustomer = "";
        this.addJobObj.customerId = 0;
        this.custDetail = null;
      }
    },
    getCustLeadInfo(custId) {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.searchForCustomer = response.Data.companyName;
          this.addJobObj.customerId = response.Data.customerId;
          this.showAddSelectAddr = true;
          this.getCustDetails(response.Data.customerId);
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    setCustomerDetail() {
      this.searchForCustomer = this.custDetail.companyName;
          if (this.custDetail.addressList !== null) {
            this.addressList = []
            this.custDetail.addressList.forEach(e => {
              let temp = 0
              let temp3 = ''
              if (this.LoginUserlatLong !== null) {
                temp = this.calcDistance(this.LoginUserlatLong.latitude, this.LoginUserlatLong.longitude, e.latitude, e.longitude)  
                temp3 = ` from user`
              } else {
                temp = this.calcDistance(this.OfficelatLong.latitude, this.OfficelatLong.longitude, e.latitude, e.longitude)
                temp3 = ` from office`
              }
              
              let value = this.getDistanceUnit(temp * 1000 )
            

            this.addressList.push({...e, selected: false, displayDistance: value  + temp3,  addressText : `(${e.addressTitle === '' ? 'Other' : e.addressTitle}) -` + ` ${e.fullAddress}`})  
            });
            // this.addressList = response.Data.addressList !== null ? response.Data.addressList : [];
          }
          if (this.addressList.length > 0 && !this.isOfficeAddressSel && !this.isAddedNewAddress) {
            this.addressList[0].selected = true
            this.jobDisplayAdd = this.addressList[0].fullAddress;
            this.addJobObj.addressLine1 = this.addressList[0].addressLine1;
            this.addJobObj.addressLine2 = this.addressList[0].addressLine2;
            this.addJobObj.addressTitle = this.addressList[0].addressTitle;
            this.addJobObj.city = this.addressList[0].city;
            this.addJobObj.state = this.addressList[0].state;
            this.addJobObj.zip = this.addressList[0].zip;
            this.addJobObj.latitude = this.addressList[0].latitude;
            this.addJobObj.longitude = this.addressList[0].longitude;
            this.addJobObj.country = this.addressList[0].country;
            this.addJobObj.directionNote = this.addressList[0].directionNote;
            this.addJobObj.houseNo = this.addressList[0].houseNo;
            this.addJobObj.floorNumber = this.addressList[0].floorNumber;
            this.addJobObj.buildingName = this.addressList[0].buildingName;
          }
    },
    getCustDetails(custId) {
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.custDetail = response.Data;
          this.setCustomerDetail()
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    setAddress(data, isNotNew) {
      if (!isNotNew) {
        this.isAddedNewAddress = true;
         let temp = 0
              let temp3 = ''
              if (this.LoginUserlatLong !== null) {
                temp = this.calcDistance(this.LoginUserlatLong.latitude, this.LoginUserlatLong.longitude, data.latitude, data.longitude)  
                temp3 = ` from user`
              } else {
                temp = this.calcDistance(this.OfficelatLong.latitude, this.OfficelatLong.longitude, data.latitude, data.longitude)
                temp3 = ` from office`
              }
              let value = this.getDistanceUnit(temp * 1000 )
        this.otherLocationDistance = value  + temp3
        this.isOfficeAddressSel = false
        this.addJobObj.isAddressAddToCustomer = true;
        this.addressList.forEach(e => {
            e.selected = false 
        });
      }
      this.jobDisplayAdd = data !== null ? data.fullAddress : "";
      this.addJobObj.addressTitle = data !== null ? data.addressTitle : "";
      this.addJobObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.addJobObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.addJobObj.city = data !== null ? data.city : "";
      this.addJobObj.state = data !== null ? data.state : "";
      this.addJobObj.zip = data !== null ? data.zip : "";
      this.addJobObj.latitude = data !== null ? data.latitude : 0;
      this.addJobObj.longitude = data !== null ? data.longitude : 0;
      this.addJobObj.country = data !== null ? data.country : "";
      this.addJobObj.geofenceRangeDiameter =
        data !== null ? data.selectedRadius : "";
      this.addJobObj.fullAddress = data !== null ? data.fullAddress : "";
      this.addJobObj.houseNo = data !== null ? data.houseNo : "";
      this.addJobObj.floorNumber = data !== null ? data.floorNumber : "";
      this.addJobObj.buildingName = data !== null ? data.buildingName : "";
      this.addJobObj.directionNote = data !== null ? data.directionNote : "";
      this.addJobObj.contactNumber = data !== null ? data.contactNumber : "";
      this.addJobObj.contactName = data !== null ? data.contactName : "";

      


      if (this.LoginUserlatLong !== null) {
        let dist = this.calcDistance(this.LoginUserlatLong.latitude, this.LoginUserlatLong.longitude, data.latitude, data.longitude)  
        let final = this.getDistanceUnit(dist * 1000 )
        this.displayDistance = final + ' from user'
      } else if (this.OfficelatLong !== null) {
        let dist = this.calcDistance(this.addJobObj.latitude,this.addJobObj.longitude, this.OfficelatLong.latitude, this.OfficelatLong.longitude)
        let final = this.getDistanceUnit(dist * 1000 )
        final +=  ' km from Office'
         this.displayDistance = final + ' from Office'
      }

    },
    selectJobAddress(data, index) {
      this.isOfficeAddressSel = false
      this.addressList.forEach((e,i) => {
        if (index !== i) {
          e.selected = false
        } else {
          e.selected = true
        }
      });
      this.isAddedNewAddress = false;
      this.addedAddress = null
      this.addJobObj.isAddressAddToCustomer = false;
      this.jobDisplayAdd = data !== null ? data.addressText : "";
      this.addJobObj.addressTitle = data !== null ? data.addressTitle : "";
      this.addJobObj.fullAddress = data !== null ? data.fullAddress : "";
      this.addJobObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.addJobObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.addJobObj.city = data !== null ? data.city : "";
      this.addJobObj.state = data !== null ? data.state : "";
      this.addJobObj.zip = data !== null ? data.zip : "";
      this.addJobObj.latitude = data !== null ? data.latitude : 0;
      this.addJobObj.longitude = data !== null ? data.longitude : 0;
      this.addJobObj.country = data !== null ? data.country : "";
      this.addJobObj.directionNote = data !== null ? data.directionNote : "";
      this.addJobObj.houseNo = data !== null ? data.houseNo : "";
      this.addJobObj.floorNumber = data !== null ? data.floorNumber : "";
      this.addJobObj.buildingName = data !== null ? data.buildingName : "";
    },
    checkboxCheck() {
        this.addressList.forEach(e => {
            e.selected = false
        });
      this.isOfficeAddressSel = true;
      this.isAddedNewAddress = false;
      this.addedAddress = null
      this.errObj.addressErr = "";
      this.jobDisplayAdd = "";
      this.addJobObj.isAddressAddToCustomer = false;
      this.addJobObj.fullAddress = "";
      this.addJobObj.addressTitle = "";
      this.addJobObj.addressLine1 = "";
      this.addJobObj.addressLine2 = "";
      this.addJobObj.city = "";
      this.addJobObj.state = "";
      this.addJobObj.zip = "";
      this.addJobObj.latitude = 0;
      this.addJobObj.longitude = 0;
      this.addJobObj.country = "";
      this.addJobObj.directionNote = "";
      this.addJobObj.houseNo = "";
      this.addJobObj.floorNumber = "";
      this.addJobObj.buildingName = "";
      if (!this.isOfficeAddressSel) {
        this.getCustDetails(this.addJobObj.customerId);
      }
    },
    crateJob() {
      let isValid = true;
      if (this.addJobObj.jobTitle.trim() === "") {
        this.errObj.titleErr = "Job title is required";
        isValid = false;
      }
      if (this.addJobObj.customerId === 0) {
        this.errObj.custErr = "Please select customer";
        isValid = false;
      }
      if (!this.isOfficeAddressSel) {
        if (this.addJobObj.latitude === 0 && this.addJobObj.longitude === 0) {
          this.errObj.addressErr = "Please select or add address";
          isValid = false;
        }
      }
      if (isValid) {
        this.$store.dispatch("SetLoader", {
          status: true,
          message: "Loading...",
        });

   if (this.addJobObj.jobStartTime === '' && this.addJobObj.jobEndTime === '') {
      this.addJobObj.jobStartTime = new Date().toUTCString();
      let currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + 1);
      this.addJobObj.jobEndTime = new Date(currentDate).toUTCString();
    }
        MyJobApp.addNewJobStepOne(
          this.addJobObj,
          (response) => {
            this.tabTitle[0].active = false;
            this.tabTitle[0].complete = true;
            this.tabTitle[1].active = true;
            this.tabTitle[1].disabled = false;
            this.tabTitle[2].disabled = false;

            this.$router.push({
              path: "/job/addNew",
              query: { jobId: response.Data },
            }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
            });


            this.getsJobDetail();
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: response.message,
              color: "success",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (error) => {
            this.$store.dispatch("SetAlert", {
              showAlert: true,
              message: error.message,
              color: "error",
            });
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
    getsJobDetail(callCustomer) {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Loading...",
      });
      MyJobApp.getJobDetail(
        parseInt(this.$route.query.jobId),
        (response) => {
          this.jobDetailObj = response.Data
          this.displayJobTypeName = response.Data.jobTypeName
          this.stepTwoObj.isBillable = false
          this.stepTwoObj.isVipJob = response.Data.isVipJob
          this.stepTwoObj.jobDescription = response.Data.jobDescription
          this.stepTwoObj.jobTypeId = response.Data.jobTypeId
          this.stepTwoObj.jobId = response.Data.jobId
          this.stepThreeObj.jobId = response.Data.jobId
          this.stepTwoObj.jobEstimation = response.Data.jobEstimation
          this.hourInputValue = Math.floor(response.Data.jobEstimation / 60)
          this.minInputValue = response.Data.jobEstimation % 60
          this.tabTitle[0].complete = true;
          this.tabTitle[1].disabled = false;
          this.tabTitle[2].disabled = false;
          this.setAddress(response.Data, true);
          // this.searchForCustomer = response.Data.companyName;
          this.addJobObj.jobId = response.Data.jobId;
          this.addJobObj.customerId = response.Data.customerId;
          this.addJobObj.jobTitle = response.Data.jobTitle;
          this.addJobObj.isAddressAddToCustomer = false;
          this.showAddSelectAddr = true;
          if (callCustomer) {
            this.getCustDetails(parseInt(response.Data.customerId));
          }
          this.setDisplayEstimation();
          if (this.jobDetailObj) {
            this.jobTypeList.forEach(element => {
            if (element.jobTypeId === this.jobDetailObj.jobTypeId) {
              this.jobCheckList = JSON.parse(element.jobCheckList)
            }
          });
          }
          
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch("SetAlert", {
            showAlert: true,
            message: err.message,
            color: "error",
          });
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    jobTypelist() {
      MyJobApp.jobTypelist(
        (response) => {
          this.jobTypeList = response.Data
          if (this.jobDetailObj) {
            this.jobTypeList.forEach(element => {
            if (element.jobTypeId === this.jobDetailObj.jobTypeId) {
              this.jobCheckList = JSON.parse(element.jobCheckList)
            }
          });
          }
        },
        (err) => {
          this.$store.dispatch("SetAlert", {
            showAlert: true,
            message: err.message,
            color: "error",
          });
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  calculateDistance (lat1, lon1, lat2, lon2, unit) {
      if (lat1 === lat2 && lon1 === lon2) {
        return 0;
      }
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist *= 1.609344;
      }
      if (unit === 'N') {
        dist *= 0.8684;
      }
      return dist;
    },
    getToolList() {
      MyJobApp.getToolList(
        (response) => {
          response.Data.forEach(element => {
          this.toolMainList.push({...element, selected:false})  
          });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getDistanceUnit (distanceMeter) {
      let distance = '';
      let countryCode;
      const moment = require('moment-timezone');
      const countryTimeZone = moment.tz.guess();  // Guesses the user's time zone
      timeZoneWithCountryCode.map(itm => {
        const ind = itm.timezones.findIndex(x => x.name === countryTimeZone);
        if (ind >= 0) {
          countryCode = itm.code;
        }
      });
      const index = milesCountry.findIndex(x => x.countryCode === countryCode);
      if (index >= 0) {
        if (distanceMeter * 0.00062137 >= 1000) {
          distance = `999+ mi`;
        } else if (distanceMeter * 0.00062137 < 0.1) {
          distance = '';
        } else {
          distance = `${(distanceMeter * 0.00062137).toFixed(1)} mi`;
        }
      } else if (distanceMeter / 1000 >= 1000) {
        distance = `999+ km`;
      } else if (distanceMeter / 1000 < 0.1) {
        distance = '';
      } else {
        distance = `${(distanceMeter / 1000).toFixed(1)} km`;
      }
      return distance;
    },
    calcDistance(lat1, lon1, lat2, lon2) {
      const radius = 6371; // Earth's radius in km
      const dLat = this.toRadians(lat2 - lat1);
      const dLng = this.toRadians(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.toRadians(lat1)) * Math.cos(this.toRadians(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = radius * c;
      return distance.toFixed(2);
    },
    toRadians(degree) {
      return degree * (Math.PI / 180);
    },
  },
};
</script>
<style scoped>
</style>